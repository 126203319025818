import Vue from 'vue/dist/vue.min.js';
import { dayjs } from '../components/dayjs-localized';
import cmgSimulator from '../calculators/mixins/cmgSimulator';

document.addEventListener('turbo:load', function() {
  window.cmg_simulator = undefined;
  const assmatCmgSimulatorElement = document.getElementById('paje-simulator');

  if (assmatCmgSimulatorElement) {
    // We add the cmg simulator to the global scope so that we can interact with it
    // on page load. We need data from the back end to feed our script
    // One point of amelioration could be to use ajax instead of global scope
    window.cmg_simulator = new Vue({
      el: '#paje-simulator',
      mixins: [cmgSimulator],
      data() {
        return {
          gross_hourly_wage: '4',
          grossHourlyWageInput: '4,00',
          minimum_gross_hourly_wage: 0,
          maximum_gross_hourly_wage: 15.00,
          ie: '3,20',
        };
      },
      watch: {
        weekly_worked_days: function() {
          this.month_from_week();
          this.check_errors();
        },
        weekly_hours: function() {
          this.month_from_week();

          this.has_overtime = this.weekly_hours_f > 45;

          this.check_errors();
        },
        ie: function() {
          this.check_errors();
        },
        has_overtime: function() {
          const $plannedOvertimeToggle = $('.toggle.planned_overtime');
          $plannedOvertimeToggle.prop('checked', this.has_overtime);
          $plannedOvertimeToggle.change();
          const that = this;
          setTimeout(function() {
            that.check_errors();
          });
        },
        cmg_not_available() {
          this.updateSliderColor(document.getElementById('paje-simulator-slider'), '', this.cmg_not_available);
        },
      },
      computed: {
        handicap_toggle_infos: function() {
          return this.handicap_toggle
            ? '<p>Le montant est majoré de 30% quand votre enfant est en situation d\'handicap.</p>'
            : '';
        },
        monthly_overtime_f: function() {
          return this.is_month
            ? parseFloat(this.monthly_overtime.replace(',', '.'))
            : (
                this.weekly_overtime_f / this.weekly_worked_days_f * this.monthly_worked_days_f
              );
        },
        weekly_overtime_f: function() {
          return this.has_overtime ? this.weekly_hours_f - 45 : 0;
        },
        ie_f: function() {
          return parseFloat(this.ie.replace(',', '.'));
        },
        ie_monthly: function() {
          return this.ie_f * this.ie_days;
        },
        ie_monthly_detax_limit: function() {
          return Math.min(this.ie_f, 2.65) * this.ie_days;
        },
        ie_days: function() {
          return (this.is_full_year ? this.monthly_worked_days_f * 47.0 / 52.0 : this.monthly_worked_days_f);
        },
        net_hourly_wage: function() {
          return this.gross_hourly_wage_f * this.net_ratio_classic;
        },
        gross_mensualization_regular: function() {
          return this.regular_hours * this.gross_hourly_wage_f;
        },
        gross_mensualization_overtime: function() {
          return this.has_overtime ? this.monthly_overtime_f * this.gross_hourly_wage_f : 0;
        },
        gross_monthy_overtime_increase: function() {
          return this.has_overtime
            ? this.monthly_overtime_f * this.gross_hourly_wage_f * this.increase_overtime_i / 100
            : 0;
        },
        net_mensualization_value_with_ie: function() {
          return parseFloat(this.net_mensualization) + parseFloat(this.ie);
        },
        cmg_not_available: function() {
          return this.min_child_too_old || this.high_mensualization || this.cmgAidToggle === 'no';
        },
        is_calculable: function() {
          switch (this.month_week) {
            case 'month':
              if (!this.is_monthly_hours || !this.is_monthly_worked_days) return false;
              if (this.has_overtime && !this.is_monthly_overtime) return false;
              if (this.has_overtime && (this.monthly_overtime_f > this.monthly_hours_f)) return false;
            case 'week':
              if (!this.is_weekly_hours || !this.is_weekly_worked_days || !this.is_yearly_weeks) return false;
          }
          // aucune idée de pourquoi mais si mis en condition supplémentaire dans le case, renvoie sytématiquement false pour les autres conditions
          if (this.month_week === 'other') return false;

          return this.is_ie;
        },
        is_weekly_hours: function() {
          return this.weekly_hours != '' && this.weekly_hours_f >= 0 && this.weekly_hours_f <= 75;
        },
        is_yearly_weeks: function() {
          return this.yearly_weeks !== '' &&
              (this.yearly_weeks_f === 52 ||
                  (this.yearly_weeks_f >= 0 &&
                      (this.yearly_weeks_f <= 46 ||
                          (this.contract_ends_before_one_year && this.yearly_weeks_f < 52)
                      )
                  )
              );
        },
        is_ie: function() {
          return this.ie != '' && this.ie_f >= 0 && this.ie_f <= 50;
        },
        net_vacations_monthly: function() {
          return this.is_full_year ? 0 : this.net_partial_year_vacations_monthly;
        },
        gross_vacations_monthly: function() {
          return this.is_full_year ? 0 : this.gross_partial_year_vacations_monthly;
        },
        total_amount: function() {
          return this.gross_total + this.social_contributions_employer + this.ie_monthly;
        },
        net_paid_total: function() {
          // Attention on a retiré le+ this.net_vacations_monthly   pour les contrats en année incomplète
          return this.net_mensualization + this.net_monthy_overtime_increase + this.ie_monthly;
        },
        gross_total: function() {
          // Attention on a retiré le + this.gross_vacations_monthly pour les contrats en année incomplète
          return this.gross_mensualization + this.gross_monthy_overtime_increase;
        },
        social_contributions_credit: function() {
          return this.cmg_not_available ? 0 : (this.social_contributions_employee + this.social_contributions_employer);
        },
      },
      methods: {
        cmg_amount: function(range_index) {
          let amount;
          if (this.child_age_option === '6' || this.cmg_not_available) {
            amount = 0;
          } else {
            // if the parent works partially we must divide the cmg amount
            const divideMaxAMount = this.cmgAidToggle === 'partial' ? 2 : 1;
            const maximumAmount = this.maximum_amount(range_index) / divideMaxAMount;

            // Attention on a retiré le this.net_vacations_monthly pour les contrats en année incomplète
            const perceivableAmount = ((
              this.net_mensualization +
                this.net_monthy_overtime_increase +
                this.ie_monthly
            ) * 0.85) / divideMaxAMount;

            amount = Math.min(maximumAmount, perceivableAmount);
          }
          return amount;
        },
        remaining_amount: function(i) {
          return (this.total_amount - this.social_contributions_credit - this.cmg_amount(i) - this.other_aid_f);
        },
        taxes_credit: function(i) {
          return this.child_age_option === 6
            ? 0
            : Math.min(Math.max(
              (this.gross_total + this.social_contributions_employer +
                  Math.min(this.ie_monthly_detax_limit, this.ie_monthly) - this.social_contributions_credit -
                  this.cmg_amount(i) - this.other_aid_f) * 12 / 2, 0), 1750);
        },
        week_from_month: function() {
          if (this.is_monthly_overtime) {
            let weekly_overtime = 0;
            this.weekly_hours = '40';

            if (this.is_weekly_worked_days && this.is_monthly_worked_days) {
              weekly_overtime = ((this.monthly_overtime_f / this.monthly_worked_days_f) * this.weekly_worked_days_f);
            }

            if (this.is_monthly_hours && this.is_weekly_worked_days && this.is_monthly_worked_days) {
              const normalWorkingHours = this.monthly_hours_f - this.monthly_overtime_f;
              this.weekly_hours = this.toFloatForDisplay(((normalWorkingHours / this.monthly_worked_days_f * this.weekly_worked_days_f) + weekly_overtime), 2);
            }
          } else {
            if (this.is_monthly_hours && this.is_weekly_worked_days && this.is_monthly_worked_days) {
              this.weekly_hours = this.toFloatForDisplay((this.monthly_hours_f * 12 / this.yearly_weeks_f), 2);
              this.weekly_worked_days = this.toFloatForDisplay(this.monthly_worked_days_f * 12 / this.yearly_weeks_f, 2);
            } else {
              this.weekly_hours = '40';
            }
          }
        },
        social_contribution_is_relevant: function(name) {
          const contribution = this.social_contributions[name];

          if (contribution.care_type === 'ged') return false;
          if (typeof contribution.alsace_moselle_specific !== 'undefined' && !this.alsace_moselle) return false;
          if (typeof contribution.start_date !== 'undefined' && dayjs() < dayjs(contribution.start_date)) return false;
          if (typeof contribution.end_date !== 'undefined' && dayjs() > dayjs(contribution.end_date)) return false;

          return true;
        },
        check_errors: function() {
          $('.wrapper_input.simulator_weekly_worked_days .input-group').toggleClass('is-invalid', !this.is_weekly_worked_days);
          $('.wrapper_input.simulator_weekly_hours .input-group').toggleClass('is-invalid', !this.is_weekly_hours);
          $('.wrapper_input.simulator_yearly_weeks .input-group').toggleClass('is-invalid', !this.is_yearly_weeks);
          $('.wrapper_input.simulator_monthly_worked_days .input-group').toggleClass('is-invalid', !this.is_monthly_worked_days);
          $('.wrapper_input.simulator_monthly_hours .input-group').toggleClass('is-invalid', !this.is_monthly_hours);
          $('.wrapper_input.simulator_daily_maintenance_allowance .input-group').toggleClass('is-invalid', !this.is_ie);
          $('.wrapper_input.simulator_monthly_overtime .input-group').toggleClass('is-invalid', !this.is_monthly_overtime);
        },
        updateSliderColor(element, value, cmg_not_available) {
          const tooltip = element.querySelector('.noUi-tooltip');
          const slider_jauge = element.querySelector('.noUi-connect');
          const input_group = element.querySelector('.input-group');

          if (cmg_not_available === true) {
            tooltip.classList.add('with-error');
            if (!tooltip.querySelector('.cmg-maxerror')) {
              tooltip.innerHTML += '' +
                  '<div class="cmg-maxerror text-danger">' +
                  '<span class="red dotted" data-scroll-to="employer-cost">Alerte</span>' +
                  '</div>';
            }
            slider_jauge.style.background = 'red';
          } else {
            tooltip.classList.remove('with-error');
            slider_jauge.style.background = '#2DA3ED';
          }
        },
        create_gross_hourly_wage_slider: function() {
          const that = this;

          // Non-linear slider : on fait en sorte que le taux horaire puisse avoir un maximum très haut, sans impacter l'ensemble du slider
          this.slider = noUiSlider.create(document.getElementById('paje-simulator-slider'), {
            start: this.gross_hourly_wage_f,
            tooltips: [true],
            connect: 'lower',
            range: {
              min: this.minimum_gross_hourly_wage,
              '75%': this.minimum_gross_hourly_wage * 2,
              max: this.maximum_gross_hourly_wage,
            },
            step: 0.01,
            format: {
              to: function(value) {
                return '<b>' + that.to_money(value) +
                    '</b><br/><span class=\'font--xs\'>(' + that.to_money(value * that.net_ratio_classic) +
                    ' net)</span>';
              },
              from: function(value) {
                return value;
              },
            },
          });

          this.slider.on('update', function(label, key, value) {
            that.gross_hourly_wage = that.toFloatForDisplay(value[0], 2);
            if (!that.hourlyWageSetFromInput) {
              that.grossHourlyWageInput = that.gross_hourly_wage;
            }
            that.updateSliderColor(document.getElementById('hourly-rate-container'), value[key], that.cmg_not_available);
          });
        },
      },
      mounted() {
        this.$nextTick(function() {
          this.toggleMW();
          this.month_from_week();
          this.create_gross_hourly_wage_slider();
        });
      },
    });
  }

  $('body').on('click', '.cmg-maxerror', function() {
    const element = document.getElementById('global-result');
    element.scrollIntoView();
  });
});
